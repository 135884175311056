import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import {
  Bars3Icon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

import image from './logo/logo.svg';
import carnlee from './images/Carnlee.jpg';
import mdba from './images/MDBA.png';

const resources = [
  {
    name: 'Puppies',
    description: 'Pets or breeding',
    href: '/ForSalePuppies',
  },
  { name: 'Adults', description: 'Pets or breeding', href: '/ForSaleAdults' },
  { name: 'Studs', description: 'Stud services', href: '/ForSaleStuds' }, 
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const people = [
  {
    name: 'AdoptaFrenchie',
    imageUrl:
      image,
    twitterUrl: 'https://www.facebook.com/AdoptaFrenchie/',
  },
]

const partners = [
  // More partners...
  {
    name: 'Master Dog Breeders and Associates',
    role: 'Partner',
    imageUrl:
      mdba,
    twitterUrl: 'https://www.facebook.com/mdbaofficial/',
  },
]

export default function Example() {
  return (
    <Popover className="relative bg-white">
      <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <a href="#" className="flex">
            <span className="sr-only">Your Company</span>
            <img 
            className="h-8 w-auto sm:h-20"
            src={image}/>
          </a>
        </div>
        <div className="-my-2 -mr-2 md:hidden">
          <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">

            <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Homepage
            </a>

            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span>For Sale</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <a key={item.name} href={item.href} className="-m-3 block rounded-md p-3 hover:bg-gray-50">
                              <p className="text-base font-medium text-gray-900">{item.name}</p>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <a href="/Gallery" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Gallery
            </a>
            <a href="/Partnerships" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Partnerships
            </a>
            <a href="/AboutUs" className="text-base font-medium text-gray-500 hover:text-gray-900">
              About Us
            </a>
            <a href="/Wikipedia" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Wiki
            </a>
          </Popover.Group>
        </div>
      </div>


      <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
  <div className="space-y-12">
    <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
      <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">We are currently partnered with the MDBA, with a mutual aim to breed Frenchies ethically</h2>
    </div>
    <ul
      role="list"
      className="mx-auto space-y-16 sm:grid sm:grid-cols-2 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-1 lg:gap-16"
    >
      {partners.map((person) => (
        <li key={person.name} className="flex justify-center items-center">
          <div className="space-y-6 text-center">
            <img className="mx-auto h-40 w-40 rounded-full xl:h-56 xl:w-56" src={person.imageUrl} alt="" />
            <div className="space-y-2">
              <div className="space-y-1 text-lg font-medium leading-6">
                <h3>{person.name}</h3>
                <p className="text-indigo-600">{person.role}</p>
              </div>
              <ul role="list" className="flex justify-center space-x-5">
                <li>
                  <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Twitter</span>
                    <svg
                      className="w-6 h-6 text-blue-600 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                      />
                    </svg>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      ))}
    </ul>
  </div>
</div>

     </div>

    <footer>
      <div className="bg-white">
      <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-0">
          <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
            <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Contact Us</h2>
          </div>
          <ul
            role="list"
            className="mx-auto space-y-16 sm:grid sm:grid-cols-1 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-1"
          >
            {people.map((person) => (
              <li key={person.name}>
                  <img className="mx-auto h-20 w-20 rounded-full xl:h-40 xl:w-40" src={person.imageUrl} alt="" />
                    <div className="space-y-0 text-lg font-medium leading-6">
                      <h3>{person.name}</h3>
                      <p className="text-indigo-600">{person.role}</p>
                    </div>
                    <ul role="list" className="flex justify-center space-x-5">
                      <li>
                        <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Twitter</span>
                          <svg
                            class="w-6 h-6 text-blue-600 fill-current"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24">
                            <path
                              d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center p-6 bg-gray-200">
    <span>© Created by:</span>
    <a class="text-gray-600 font-semibold" href="https://jasonrixon.com/"> Jason Rixon</a>
  </div>
  </footer>
    
      
    </Popover>
  )
}