import { Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import image from './logo/logo.svg';
import fluffy from './images/fluffy.jpg';

const resources = [
  { name: 'Puppies', description: 'Pets or breeding', href: '/ForSalePuppies' },
  { name: 'Adults', description: 'Pets or breeding', href: '/ForSaleAdults' },
  { name: 'Studs', description: 'Stud services', href: '/ForSaleStuds' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const people = [
  {
    name: 'AdoptaFrenchie',
    imageUrl: image,
    twitterUrl: 'https://www.facebook.com/AdoptaFrenchie/',
  },
];

export default function Example() {
  return (
    <Popover className="relative bg-white">
      <div className="flex items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10">
        <div>
          <a href="#" className="flex">
            <span className="sr-only">Your Company</span>
            <img className="h-8 w-auto sm:h-20" src={image} alt="Company Logo" />
          </a>
        </div>
        <div className="-my-2 -mr-2 md:hidden">
          <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
            <span className="sr-only">Open menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden md:flex md:flex-1 md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">
            <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Homepage
            </a>
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? 'text-gray-900' : 'text-gray-500',
                      'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                    )}
                  >
                    <span>For Sale</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? 'text-gray-600' : 'text-gray-400',
                        'ml-2 h-5 w-5 group-hover:text-gray-500'
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute left-1/2 z-10 mt-3 w-screen max-w-xs -translate-x-1/2 transform px-2 sm:px-0">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                          {resources.map((item) => (
                            <a key={item.name} href={item.href} className="-m-3 block rounded-md p-3 hover:bg-gray-50">
                              <p className="text-base font-medium text-gray-900">{item.name}</p>
                              <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                            </a>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <a href="/Gallery" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Gallery
            </a>
            <a href="/Partnerships" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Partnerships
            </a>
            <a href="/AboutUs" className="text-base font-medium text-gray-500 hover:text-gray-900">
              About Us
            </a>
            <a href="/Wikipedia" className="text-base font-medium text-gray-500 hover:text-gray-900">
              Wiki
            </a>
          </Popover.Group>
        </div>
      </div>

      {/* Mobile menu, show/hide based on menu open state. */}
      <Transition
        as={Fragment}
        enter="transition ease-out duration-200"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel focus="true" className="absolute inset-x-0 top-full z-10 origin-top-right bg-white shadow-lg ring-1 ring-black ring-opacity-5 md:hidden">
          <div className="p-4">
            <div className="flex items-center justify-between">
              <img className="h-8 w-auto" src={image} alt="Company Logo" />
              <Popover.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                <span className="sr-only">Close menu</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="mt-4 space-y-4">
              <a href="/" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Homepage
              </a>
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button
                      className={classNames(
                        open ? 'text-gray-900' : 'text-gray-500',
                        'group inline-flex items-center rounded-md bg-white text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                      )}
                    >
                      <span>For Sale</span>
                      <ChevronDownIcon
                        className={classNames(
                          open ? 'text-gray-600' : 'text-gray-400',
                          'ml-2 h-5 w-5 group-hover:text-gray-500'
                        )}
                        aria-hidden="true"
                      />
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute left-0 top-full mt-2 w-64 bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                        <div className="p-4 space-y-4">
                          {resources.map((item) => (
                            <a key={item.name} href={item.href} className="block text-base font-medium text-gray-900 hover:bg-gray-50 p-2 rounded-md">
                              <p className="font-medium">{item.name}</p>
                              <p className="text-sm text-gray-500">{item.description}</p>
                            </a>
                          ))}
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
              <a href="/Gallery" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Gallery
              </a>
              <a href="/Partnerships" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Partnerships
              </a>
              <a href="/AboutUs" className="text-base font-medium text-gray-500 hover:text-gray-900">
                About Us
              </a>
              <a href="/Wikipedia" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Wiki
              </a>
            </div>
          </div>
        </Popover.Panel>
      </Transition>

      <div className="relative overflow-hidden bg-white py-16">
        <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full lg:[overflow-anchor:none]">
          <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
            <svg
              className="absolute top-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)" />
            </svg>
            <svg
              className="absolute top-1/2 right-full -translate-y-1/2 -translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)" />
            </svg>
            <svg
              className="absolute bottom-12 left-full translate-x-32 transform"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                </pattern>
              </defs>
              <rect width={404} height={384} fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)" />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-prose text-lg">
            <h1>
              <span className="block text-center text-lg font-semibold text-indigo-600">Ethical Breeding</span>
              <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
                Elimination of DVL2
              </span>
            </h1>
            <p className="mt-8 text-xl leading-8 text-gray-500">
              A recent study suggests that removing an allele (genomics) named DVL2 correlates with breathing problems among frenchies. The <a className="underline text-indigo-600" href="https://link.springer.com/article/10.1007/s00439-021-02261-8#citeas">study</a> was conducted on a small number of frenchies. So at this point in time 
              there is a hypothesis that, selectively breeding to reduce the number of copies of DVL2 will reduce the severity of breathing problems in frenchies. 
            </p>
          </div>
          <div className="prose prose-lg prose-indigo mx-auto mt-6 text-gray-500">
            <p>
              You might be wondering why it matters? Dogs are our companions, so we feel inclined to protect them from harm and reduce their suffering. 
            </p>
            <span className="block text-center text-lg font-semibold text-indigo-600">An Aesthetic Variation</span>
            <span className="mt-2 block text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl">
              Fluffy Frenchies
            </span>
            <p>
              We also breed Fluffy French Bulldogs. A mutant gene known as fibroblast growth factor 5 (FGF5) causes the long-hair phenotype in French Bulldogs (<a className="underline text-indigo-600" href="https://link.springer.com/article/10.1007/s00439-021-02261-8">source</a>). In other words, a rare mutation
              causes frenchies to grow hair far more than what is usual. Temperamentally, fluffy frenchies are just like their counterparts, always loveable and friendly. 
            </p>
            <figure>
              <img
                className="w-full rounded-lg"
                src={fluffy}
                alt="Fluffy French Bulldog"
                width={1310}
                height={873}
              />
              <figcaption>Bear</figcaption>
            </figure>
          </div>
        </div>

        <footer>
          <div className="bg-white">
            <div className="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:px-8 lg:py-24">
              <div className="space-y-0">
                <div className="space-y-5 sm:mx-auto sm:max-w-xl sm:space-y-4 lg:max-w-5xl">
                  <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Contact Us</h2>
                </div>
                <ul
                  role="list"
                  className="mx-auto space-y-16 sm:grid sm:grid-cols-1 sm:gap-16 sm:space-y-0 lg:max-w-5xl lg:grid-cols-1"
                >
                  {people.map((person) => (
                    <li key={person.name}>
                      <img className="mx-auto h-20 w-20 rounded-full xl:h-40 xl:w-40" src={person.imageUrl} alt={person.name} />
                      <div className="space-y-0 text-lg font-medium leading-6">
                        <h3>{person.name}</h3>
                        <p className="text-indigo-600">{person.role}</p>
                      </div>
                      <ul role="list" className="flex justify-center space-x-5">
                        <li>
                          <a href={person.twitterUrl} className="text-gray-400 hover:text-gray-500">
                            <span className="sr-only">Twitter</span>
                            <svg
                              className="w-6 h-6 text-blue-600 fill-current"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          <div className="text-center p-6 bg-gray-200">
            <span>© Created by:</span>
            <a className="text-gray-600 font-semibold" href="https://jasonrixon.com/"> Jason Rixon</a>
          </div>
        </footer>
      </div>
    </Popover>
  );
}
