import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Homepage";
import ForSaleAdults from "./pages/ForSaleAdults";
import ForSalePuppies from "./pages/ForSalePuppies";
import ForSaleStuds from "./pages/ForSaleStuds";
import Gallery from "./pages/Gallery";
import Partnerships from "./pages/Partnerships";
import Wikipedia from "./pages/Wikipedia";
import AboutUs from "./pages/AboutUs";
import './App.css';

function App() {
  return (
      <Router>
          <div className="App">
              <Routes>
                  <Route path="/" element={<HomePage />} />
                  <Route path="/ForSaleAdults" element={<ForSaleAdults />} />
                  <Route path="/ForSalePuppies" element={<ForSalePuppies />} />
                  <Route path="/ForSaleStuds" element={<ForSaleStuds />} />
                  <Route path="/Gallery" element={<Gallery />} />
                  <Route path="/Partnerships" element={<Partnerships />} />
                  <Route path="/Wikipedia" element={<Wikipedia />} />
                  <Route path="/AboutUs" element={<AboutUs/>} />
              </Routes>
          </div>
      </Router>
  );
}
export default App;